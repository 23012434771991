<template>
  <div class="">
    <div class="relative container">
      <p
        class="px-4 xl:px-0 font-lato font-semibold text-xl lg:text-4xl text-primary-900"
      >
        Информация
      </p>
      <div class="infoSwiper overflow-hidden px-4 pt-3 pb-5 lg:pt-5">
        <div class="swiper-wrapper">
          <div v-for="(item, index) in infoList" class="swiper-slide !w-max">
            <nuxt-link :to="item.href">
              <div
                class="py-3 px-5 w-fit flex flex-row items-center space-x-3 rounded-lg"
                style="box-shadow: 0px 0px 22px 0px #00000014"
              >
                <component
                  v-bind:is="item.icon"
                  class="w-4 h-4"
                  style="flex-shrink: 0"
                  color="#3A405A"
                ></component>

                <p class="text-lg font-lato font-semibold text-primary-400">
                  {{ item.name }}
                </p>
              </div>
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Swiper } from "swiper";
import "swiper/css/bundle";
import IconDelivery from "./p-icon/p-icon-delivery.vue";
import IconPhone from "./p-icon/p-icon-phone.vue";
import IconMenu from "./p-icon/p-icon-menu.vue";

const infoList = reactive([
  {
    name: "Каталог",
    icon: IconMenu,
    href: "/catalog",
  },
  {
    name: "Контакты",
    icon: IconPhone,
    href: "/contacts",
  },
  {
    name: "Доставка",
    icon: IconDelivery,
    href: "/delivery",
  },
]);

onMounted(() => {
  const swiper = new Swiper(".infoSwiper", {
    // freeMode: true,
    spaceBetween: 16,
    // loop: true,
    slidesPerView: "auto",
    breakpoints: {
      320: {
        slidesPerView: 2.2,
      },
      640: {
        slidesPerView: 3,
      },
      // 1024: {
      //   slidesPerView: 3,
      // },
      // 1280: {
      //   slidesPerView: 4,
      // },
    },
  });

  return { swiper };
});
</script>

<style scoped>
@media (min-width: 1200px) {
  .container {
    max-width: 1210px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
</style>
