<script setup>
const { $get } = useAxios();

const { data: popularProducts, pending: isLoading } = await useAsyncData(
  "get popular products limit 4",
  () =>
    $get(
      `/api/v1/product?limit=4&filter=isPublished||$eq||true;isPopular||$eq||true&sort=publishedDate,DESC`
    ),
  { server: false }
);
</script>

<template>
  <div v-if="!isLoading">
    <div v-if="popularProducts && popularProducts.length">
      <div
        class="container px-4 xl:px-0 flex flex-row space-x-2 items-end justify-between"
      >
        <p class="font-lato font-semibold text-xl lg:text-4xl text-primary-900">
          Популярно
        </p>
        <nuxt-link
          to="/popular"
          class="font-lato flex flex-row items-center text-secondary-200 space-x-1 text-sm"
        >
          <p>Смотреть еще</p>
          <p-icon-arrow
            class="w-3 h-3 !-rotate-90 mt-0.5"
            color="#FFA267"
          ></p-icon-arrow
        ></nuxt-link>
      </div>
      <ClientOnly>
        <p-slider-product :products="popularProducts"></p-slider-product>
      </ClientOnly>
    </div>
  </div>
  <div v-else class="container mb-4 px-4 xl:px-0">
    <p
      class="font-lato font-semibold pb-5 text-xl lg:text-4xl text-primary-900"
    >
      Популярно
    </p>
    <div
      class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4"
    >
      <p-card-sceleton class="" />
      <p-card-sceleton class="hidden sm:block" />
      <p-card-sceleton class="hidden lg:block" />
      <p-card-sceleton class="hidden xl:block" />
    </div>
  </div>
</template>
