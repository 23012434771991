<template>
  <section :class="isHover ? 'overlay-section active' : ''"></section>
  <section
    class="grid grid-cols-12 pt-5"
    :class="
      isHover
        ? 'shadow-xl bg-white rounded-xl relative min-h-[580px] z-10'
        : 'relative'
    "
    @mouseleave="clearHover"
  >
    <div class="col-start-1 col-end-4">
      <div
        v-for="(item, idx) in categories"
        :key="idx"
        class="border-l-2 hover:border-secondary-400 relative z-50 hover:bg-secondary-25 rounded-r p-[10px] cursor-pointer "
        :class="!isHover ? 'border-primary-25' : 'border-white'"
        @mouseover="hoverCategory(idx)"
      >
        <p-catalog-category :item="item" />
      </div>
    </div>

    <div class="col-start-4 col-end-13">
      <p-catalog-category-children
        :categories="categories"
        :isActive="false"
        :activeCategoryIndex="activeCategoryIndex"
      ></p-catalog-category-children>
    </div>
  </section>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
  categories: {
    type: Object,
  },
  isActive: Boolean,
});

const activeCategoryIndex = ref(null);
const isHover = ref(false);

function clearHover() {
  isHover.value = false;
  activeCategoryIndex.value = null;
}

function hoverCategory(idx) {
  activeCategoryIndex.value = idx;
  isHover.value = true;
}
</script>
<style scoped>
.overlay-section.active::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: auto;
  background: rgba(0, 0, 0, 0.1);
}
</style>
