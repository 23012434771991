<template>
  <div>
    <nuxt-layout name="default">
      <section class="pt-4 lg:pt-0 overflow-x-hidden">
        <div class="container hidden lg:grid grid-cols-12 relative pb-4">
          <div
            class="w-full h-full z-30 absolute col-start-1 col-end-13"
            @mouseover="isHover = true"
            @mouseleave="isHover = false"
          >
            <p-catalog-category-parent
              :categories="categoriesList.categories"
            />
          </div>
          <p-main-banner
            class="relative col-start-4 col-end-13 pt-5"
            :class="[isHover ? 'z-0' : 'z-40']"
          />
        </div>
        <p-main-banner
          class="hidden sm:block lg:hidden pb-4 container px-4 xl:px-0 relative z-0 pt-5 w-full"
        />
        <p-main-banner-mobile
          class="pb-4 container px-4 xl:px-0 sm:hidden px-4 xl:px-0"
        />
        <ClientOnly>
          <p-info-slider class="md:pb-2 lg:hidden" />
          <template #fallback>
            <p-info-slider-sceleton class="pb-4 lg:hidden"></p-info-slider-sceleton>
          </template>
        </ClientOnly>
        <p-popular-products class="md:pb-2" />
        <p-selection-of-products class="md:pb-2" />
        <p-viewed-products class="pb-3 md:pb-17" />
      </section>
    </nuxt-layout>
  </div>
</template>

<script setup>
import POSITIVE_LOGO from "@/assets/img/positive-logo.png";
import { useCategoryStore } from "~/stores/use-categories-store";
const categoriesList = useCategoryStore();

const isHover = ref(false);

const head = computed(() => {
  return {
    title: `ООО "Позитив"`,
    meta: [
      {
        hid: "title",
        property: "title",
        content: "Интернет-магазин Позитив",
      },
      {
        hid: "og:title",
        property: "og:title",
        content: "Интернет-магазин Позитив",
      },
      {
        hid: "description",
        name: "description",
        content:
          "Позитив - ваш выбор для дома и ремонта. Широкий ассортимент товаров: сантехника, стройматериалы, инструменты. Качество и доступные цены. Покупайте онлайн уже сегодня!",
      },
      {
        hid: "og:description",
        property: "og:description",
        content:
          "Позитив - ваш выбор для дома и ремонта. Широкий ассортимент товаров: сантехника, стройматериалы, инструменты. Качество и доступные цены. Покупайте онлайн уже сегодня!",
      },
      { hid: "url", name: "url", content: "https://positive.ooo" },
      { hid: "og:url", property: "og:url", content: "https://positive.ooo" },
      {
        hid: "image",
        name: "image",
        content: `${POSITIVE_LOGO}`,
      },
      {
        name: "og:image",
        property: "og:image",
        content: `${POSITIVE_LOGO}`,
      },
    ],
  };
});

useHead(head);
</script>
